import './Footer.css';

export default function Footer() {
    return (
        <footer>
            <p>
                By logging in you agree to the following terms. View <a href="https://www.anytimefitness.com/terms-of-use/" target="_blank">Terms of Use</a> and <a href="https://www.anytimefitness.com/privacy/" target="_blank">Privacy Policy. </a>
            </p>
        </footer>
    );
}