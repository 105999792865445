import './App.css';
import Footer from './components/footer/Footer';
import Header from './components/header/Header';
import VerificationOptions from './pages/VerificationOptions';
import { Route, Routes, useSearchParams } from 'react-router-dom';
import VerifyByKeyfob from './pages/VerifyByKeyfob';
import VerifyByAfApp from './pages/VerifyByAfApp';
import VerifyByPhone from './pages/VerifyByPhone';
import CodeVerification from './pages/CodeVerification';
import ChooseTextOrCall from './pages/ChooseTextOrCall';

function App() {
    const [searchParams] = useSearchParams();
    const redirectUrl = searchParams.get("redirect") ?? "https://localhost:5001/test_return"
    return (
        <div className="App">
            <Header />
            <main className='bg-line-blend-aqua-8'>
                <div className="center-wrapper">
                    <div className="look-up-your-members">
                        <span className="text-white">LOOK UP YOUR </span>
                        <span className="text-aqua">MEMBERSHIP</span>
                    </div>
                    <Routes>
                        <Route path="/" element={<VerificationOptions redirectUrl={redirectUrl} />} />
                        <Route path="/keyfob" element={<VerifyByKeyfob />} />
                        <Route path="/afapp" element={<VerifyByAfApp />} />
                        <Route path="/phone" element={<VerifyByPhone />} />
                        <Route path="/textorcall" element={<ChooseTextOrCall />} />
                        <Route path="/verification" element={<CodeVerification />} />
                    </Routes>
                </div>
            </main>
            <Footer />
        </div>
    );
}

export default App;
