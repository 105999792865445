import './Header.css';

export default function Header() {
    return (
        <header>
            <nav className="logo">
                <a href="https://www.anytimefitness.com/" target="_blank"><img
                    src="https://anytimefitness.blob.core.windows.net/shared-assets/sites/af/consumer/img/logo-af-white.svg"
                    alt="Anytime Fitness"
                    title="Anytime Fitness" /></a>
            </nav>
        </header>
    );
}