import React, { ChangeEvent } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import useApi from '../api';
import AllVerificationOptionsButton from '../components/AllVerificationOptionsButton';
import { RedirectResource } from '../models/RedirectResource';
import './VerifyByKeyfob.css';

export default function VerifyByKeyfob() {
    const api = useApi();
    const [keyfob, setKeyfob] = React.useState('');
    const [keyfobValid, setKeyfobValid] = React.useState(true);
    const [lastName, setLastName] = React.useState('');
    const [lastNameValid, setLastNameValid] = React.useState(true);
    const [errorMessage, setErrorMessage] = React.useState('');
    const { state } = useLocation();
    const redirectUrl = state.redirectUrl;

    function handleKeyfobChange(evt: ChangeEvent<HTMLInputElement>) {
        setKeyfob(evt.target.value);
    }

    function handleLastNameChange(evt: ChangeEvent<HTMLInputElement>) {
        setLastName(evt.target.value);
    }

    function validateKeyfobAndLastname(): boolean {
        let keyfobInvalid = !keyfob || keyfob == ''
        let lastNameInvalid = !lastName || lastName == '';
        if (keyfobInvalid) {
            setKeyfobValid(false);
        } else {
            setKeyfobValid(true);
        }

        if (lastNameInvalid) {
            setLastNameValid(false);
        } else {
            setLastNameValid(true);
        }

        return !keyfobInvalid && !lastNameInvalid;
    }

    function handleSubmit(evt: React.MouseEvent<HTMLButtonElement>) {
        setErrorMessage('');

        let isValid = validateKeyfobAndLastname();

        if (!isValid) {
            return;
        }

        api.post<RedirectResource>(`api/v1/memberlookup`, {
            keyfob: keyfob,
            lastName: lastName,
            redirectUrl: redirectUrl
        })
            .then(response => {
                if (response.isSuccess && response.json && response.json.redirectUrl) {
                    window.location.href = response.json.redirectUrl;
                } else {
                    if (response.status === 401){
                        setErrorMessage('The key fob and last name do not match an account in our system. Please try another combination or another verification option.');
                    }
                    if(response.status === 403){
                        window.location.href = "https://www.anytimefitness.com/"
                    }     
                }
            })
            .catch(_ => {
                setErrorMessage('There was an unexpected problem handling your request. Please try again');
            });
    }

    return (
        <div className='verification-options-wrapper'>
            <AllVerificationOptionsButton />
            <p className="heading">VERIFY BY KEY FOB</p>
            <section className="main">
                <div className="info-section">
                    <p>
                        <strong>
                            Locate your AF Key Fob:
                        </strong>
                    </p>
                    <p>
                        If your key fob has separate character groups please enter the six characters in the middle.
                    </p>
                    <p>If your key fob has fewer characters please enter them all.</p>
                    <p className='required'>
                        * Required
                    </p>
                </div>
                <div className="keyfob-section">
                    <img src="http://anytimefitness.github.io/mocks1402/mock-af-consumer/img/keyfobs-old.png" alt="" />
                    <img src="http://anytimefitness.github.io/mocks1402/mock-af-consumer/img/keyfobs-new.png" alt="" />
                </div>
            </section>
            <section className="full-width">
                <label htmlFor="keyfob" className="form-label">Key Fob*</label>
                <input className={keyfobValid ? 'form-control mb-1' : 'form-control mb-1 error'} required id='keyfob' placeholder="Enter your key fob" value={keyfob} onChange={handleKeyfobChange} />
                {!keyfobValid && <p className="validation-error">Please enter a keyfob.</p>}
                <label htmlFor="last-name" className="form-label">Last Name*</label>
                <input className={lastNameValid ? 'form-control mb-1' : 'form-control mb-1 error'} id='last-name' placeholder="Enter your last name" value={lastName} onChange={handleLastNameChange} />
                {!lastNameValid && <p className="validation-error">Please enter a last name.</p>}
                <button onClick={handleSubmit} >
                    Submit
                </button>
                {errorMessage !== '' && <div className="mt-1 error">{errorMessage}</div>}
            </section>
        </div>
    );
}