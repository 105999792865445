import { Link } from 'react-router-dom';
import './VerificationOptions.css';

export default function VerificationOptions({ redirectUrl }: { redirectUrl: string | null }) {
    return (
        <div className="verification-options-wrapper">
            <p className="verification-options">VERIFICATION OPTIONS</p>
            <p className="verify-your-anytime">Verify your Anytime Fitness membership using one of the following options.</p>
            <Link to={'/phone'} state={{ redirectUrl: redirectUrl }}>
                <button type="button" className="button">
                    <span>Phone</span>
                </button>
            </Link>
            <Link to={'/keyfob'} state={{ redirectUrl: redirectUrl }}>
                <button type="button" className="button">
                    <span>Key Fob</span>
                </button>
            </Link>
            <Link to={'/afapp'} state={{ redirectUrl: redirectUrl }}>
                <button type="button" className="button">
                    <span>AF App Account</span>
                </button>
            </Link>
        </div>
    );
}