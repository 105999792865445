import { useLocation } from "react-router-dom";
import AllVerificationOptionsButton from "../components/AllVerificationOptionsButton";
import { apiUrl } from "../env";

export default function VerifyByAfApp() {
    const { state } = useLocation();
    const redirectUrl = state.redirectUrl;

    function handleSubmit(evt: React.MouseEvent<HTMLButtonElement>) {
        window.location.href = `${apiUrl}api/v1/sso/?redirectUrl=${redirectUrl}`
    }

    return (
        <div className='verification-options-wrapper'>
            <AllVerificationOptionsButton />
            <p className="heading">VERIFY BY AF APP ACCOUNT</p>
            <p className="verify-your-anytime">If you use our mobile app on your Android or iOS device, you can log in using the same email and password.</p>
            <p>Click below to be redirected to our login page.</p>
            <button type="button" className="button" onClick={handleSubmit}>Log In</button>
        </div>
    );
}