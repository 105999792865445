const hostname = window.location.hostname;

const apiUrl = (hostname.includes('localhost') || hostname.includes('demo'))
    ? "https://demo-memberauthapi.anytimefitness.com/"
    : (hostname.includes('stage')
        ? "https://stage-memberauthapi.anytimefitness.com/"
        : "https://memberauthapi.anytimefitness.com/");

function apiEndpoint(relativeUrl: string): string {
    const { href } = new URL(relativeUrl, apiUrl);

    return href;
}

export { apiUrl, apiEndpoint };