import PhoneInput from "react-phone-input-2";
import AllVerificationOptionsButton from "../components/AllVerificationOptionsButton";
import 'react-phone-input-2/lib/bootstrap.css'
import React, { ChangeEvent } from "react";
import useApi from "../api";
import { MemberResponse } from "../models/MemberResource";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import './VerifyByPhone.css';

export default function VerifyByPhone() {
    const api = useApi();
    const [phoneNumber, setPhoneNumber] = React.useState('');
    const [errorMessage, setErrorMessage] = React.useState('');
    const navigate = useNavigate();
    const { state } = useLocation();
    const redirectUrl = state.redirectUrl;

    function handlePhoneChange(phoneValue: string) {
        var prefix = '+';
        if (!phoneValue.startsWith('+')) {
            phoneValue = prefix + phoneValue;
        }
        setPhoneNumber(phoneValue);
    }

    function handleSubmit(evt: React.MouseEvent<HTMLButtonElement>) {
        setErrorMessage('');
        api.post<MemberResponse>(`api/v1/verification/phone/isvalid`, phoneNumber)
            .then(response => {
                if (response.isSuccess) {
                    if (response.json) {
                        navigate('/textorcall', { state: { member: response.json.memberData, redirectUrl: redirectUrl } })
                    }
                } else {
                    setErrorMessage('There is no member matching the provided phone number.');
                }
            })
            .catch(_ => {
                setErrorMessage('There was an unexpected problem handling your request. Please try again');
            });
    }

    return (
        <div className='verification-options-wrapper'>
            <AllVerificationOptionsButton />
            <p className="heading">VERIFY BY PHONE</p>
            <p>Please enter the phone number associated with your Anytime Fitness membership.</p>
            <p className="phone-note"><span>Note: </ span>Phone verification is only available to members who have created an account in the latest version of the AF App.</p>
            <label htmlFor="phone-number" className="form-label">Phone</label>
            <PhoneInput country={'us'} containerClass={'mb-1'} onChange={phone => handlePhoneChange(phone)} value={phoneNumber} />
            <button type="button" className="button" onClick={handleSubmit}>Continue</button>
            {errorMessage !== '' && <div className="mt-1 error">{errorMessage}</div>}
        </div>
    );
}