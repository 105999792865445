import React, { ChangeEvent } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useApi from "../api";
import AllVerificationOptionsButton from "../components/AllVerificationOptionsButton";
import { AccessTokenResource } from "../models/AccessTokenResource";
import { MemberResource } from "../models/MemberResource";
import './CodeVerification.css';

export default function CodeVerification() {
    const [errorMessage, setErrorMessage] = React.useState('');
    const [verificationCode, setVerificationCode] = React.useState('');
    const [validVerificationCode, setValidVerificationCode] = React.useState(true);
    const { state } = useLocation();
    const navigate = useNavigate();
    const api = useApi();

    const member: MemberResource = state?.member;
    const verificationType: string = state?.verificationType;

    React.useEffect(() => {
        if (!member || !verificationType) {
            navigate('/');
        }
    }, [member])
    if (!member) {
        return <></>;
    }

    const redirectUrl = state?.redirectUrl;

    function handleVerificationCodeChange(evt: ChangeEvent<HTMLInputElement>) {
        setVerificationCode(evt.target.value);
    }

    function buildRedirectUrl(token: string) {

        var queryDelim = redirectUrl.includes("?") ? "&" : "?";

        return `${redirectUrl}${queryDelim}${token}=${(redirectUrl.includes("localhost") ? "{\"test\":\"data\"}" : token)}`;
    }

    function validateVerifcationCode(): boolean {
        if (!verificationCode || verificationCode == '') {
            setValidVerificationCode(false);
            return false;
        }
        else {
            setValidVerificationCode(true);
            return true;
        }
    }

    function handleSubmit() {
        setErrorMessage('');

        let isValid = validateVerifcationCode();

        if (!isValid) {
            return;
        }

        api.post<AccessTokenResource>(`api/v1/verification/isvalid`, {
            phoneNumberOrEmailAddress: member.mobilePhone,
            verificationType: verificationType,
            verificationCode: verificationCode,
            userId: member.userId
        })
            .then(response => {
                if (response.isSuccess && response.json) {
                    var token = response.json.accessToken;
                    var redirect = buildRedirectUrl(token);
                    window.location.href = redirect;
                } else {
                    if(response.status == 403){
                        window.location.href = "https://www.anytimefitness.com"
                    }
                    setErrorMessage('The verification code does not match.');
                }
            })
            .catch(_ => {
                setErrorMessage('There was an unexpected problem handling your request. Please try again');
            });
    }

    function handleResend() {
        api.post(`api/v1/verification/create`, {
            phoneNumberOrEmailAddress: member.mobilePhone,
            verificationType: verificationType,
            locale: 'EN'
        })
            .then(response => {
                if (!response.isSuccess) {
                    setErrorMessage('There was an unexpected problem handling your request. Please try again');
                }
            })
            .catch(_ => {
                setErrorMessage('There was an unexpected problem handling your request. Please try again');
            });
    }

    return (
        <div className='verification-options-wrapper'>
            <AllVerificationOptionsButton />
            <p className="heading">VERIFY BY PHONE</p>
            <p className="got-it"><span className="icon-check"></span>Code sent!</p>
            <p>Please enter the code you received.</p>
            <label htmlFor="verification-code" className="form-label">Verification Code</label>
            <input className={validVerificationCode ? 'form-control mb-1' : 'form-control mb-1 error'} id='verification-code' onChange={handleVerificationCodeChange} placeholder="Enter code" />
            {!validVerificationCode && <p className="validation-error">Please enter a verification code.</p>}
            <button type="button" className="button" onClick={handleSubmit}>
                <span>Verify</span>
            </button>
            <button type="button" className="button resend-code-btn" onClick={handleResend}>
                <span>Resend Code</span>
            </button>
            <button type="button" className="back" onClick={() => navigate(-1)}>
                <span>Back</span>
            </button>
            {errorMessage !== '' && <div className="mt-1 error">{errorMessage}</div>}
        </div>
    );
}