import AllVerificationOptionsButton from "../components/AllVerificationOptionsButton";
import { MemberResource } from "../models/MemberResource";
import { useLocation, useNavigate } from "react-router-dom";
import useApi from "../api";
import React from "react";
import './ChooseTextOrCall.css';

export default function ChooseTextOrCall() {
    const [errorMessage, setErrorMessage] = React.useState('');
    const { state } = useLocation();
    const navigate = useNavigate();
    const api = useApi();
    const member: MemberResource = state?.member;

    React.useEffect(() => {
        if (!member) {
            navigate('/');
        }
    }, [member])
    if (!member) {
        return <></>;
    }

    const redirectUrl = state?.redirectUrl;

    function handleSubmit(verificationType: string) {
        api.post(`api/v1/verification/create`, {
            phoneNumberOrEmailAddress: member.mobilePhone,
            verificationType: verificationType,
            locale: 'EN'
        })
            .then(response => {
                if (response.isSuccess) {
                    navigate('/verification', { state: { member: member, verificationType: verificationType, redirectUrl: redirectUrl } })
                } else {
                    setErrorMessage('There was an unexpected problem handling your request. Please try again');
                }
            })
            .catch(_ => {
                setErrorMessage('There was an unexpected problem handling your request. Please try again');
            });
    }
    return (
        <div className='verification-options-wrapper'>
            <AllVerificationOptionsButton />
            <p className="heading">VERIFY BY PHONE</p>
            <p className="got-it"><span className="icon-check"></span>Got it!</p>
            <p>How should we send the verification?</p>
            <button type="button" className="button" onClick={() => handleSubmit('sms')}>
                <span>Text Me</span>
            </button>
            <button type="button" className="button" onClick={() => handleSubmit('call')}>
                <span>Call Me</span>
            </button>
            <p className="mt-1 message-rates">Standard message and data rates may apply.</p>
            <button type="button" className="back" onClick={() => navigate(-1)}>
                <span>Back</span>
            </button>
            {errorMessage !== '' && <div className="mt-1 error">{errorMessage}</div>}
        </div>
    );
}