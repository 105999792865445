import * as env from './env';

export interface ApiResponse<T extends object | undefined = undefined> {
    isSuccess: boolean,
    status: number,
    response: Response,
    text: string,
    json?: T
}

export interface IApi {
    call<T extends object | undefined = undefined>(endpoint: string, method: string, body?: any): Promise<ApiResponse<T>>;
    get<T extends object | undefined = undefined>(endpoint: string): Promise<ApiResponse<T>>;
    post<T extends object | undefined = undefined>(endpoint: string, body?: any): Promise<ApiResponse<T>>;
    put<T extends object | undefined = undefined>(endpoint: string, body?: any): Promise<ApiResponse<T>>;
    patch<T extends object | undefined = undefined>(endpoint: string, body?: any): Promise<ApiResponse<T>>;
    del<T extends object | undefined = undefined>(endpoint: string, body?: any): Promise<ApiResponse<T>>;
}

class Api implements IApi {
    _accessToken: string | undefined;

    constructor(accessToken: string | undefined) {
        this._accessToken = accessToken;
    }

    async call<T extends object | undefined = undefined>(endpoint: string, method: string = 'GET', body?: any): Promise<ApiResponse<T>> {
        const requestInfo: RequestInit = {
            method: method,
            credentials: 'include'
        };

        requestInfo.headers = {};

        if (this._accessToken) {
            requestInfo.headers['Authorization'] = `Bearer ${this._accessToken}`;
        }

        if (body) {
            requestInfo.body = JSON.stringify(body);
            requestInfo.headers['Content-Type'] = 'application/json';
        }

        const response = await fetch(env.apiEndpoint(endpoint), requestInfo);
        const text = await response.text();
        let json;

        try {
            json = JSON.parse(text);
        }
        catch (e) {
            json = undefined;
        }

        return {
            response: response,
            text: text,
            isSuccess: response.ok,
            status: response.status,
            json: json
        };
    }

    get<T extends object | undefined = undefined>(endpoint: string): Promise<ApiResponse<T>> {
        return this.call<T>(endpoint, 'GET');
    }

    post<T extends object | undefined = undefined>(endpoint: string, body?: any): Promise<ApiResponse<T>> {
        return this.call<T>(endpoint, 'POST', body);
    }

    put<T extends object | undefined = undefined>(endpoint: string, body?: any): Promise<ApiResponse<T>> {
        return this.call<T>(endpoint, 'PUT', body);
    }

    patch<T extends object | undefined = undefined>(endpoint: string, body?: any): Promise<ApiResponse<T>> {
        return this.call<T>(endpoint, 'PATCH', body);
    }

    del<T extends object | undefined = undefined>(endpoint: string, body?: any): Promise<ApiResponse<T>> {
        return this.call<T>(endpoint, 'DELETE', body);
    }
}

export default function useApi(): IApi {
    const api = new Api(undefined);
    return api;
}